import React from 'react';
import { Link, graphql } from 'gatsby';
import { SanityPost, SanityAuthor as ISanityAuthor } from '../types/Article';
import { SiteMetadata } from '../types/SiteMetadata';
import Content from '../layouts/main';
import styled from '../styled';
import { Typography, Tooltip, IconButton } from '@material-ui/core';
import { Linkedin, Facebook, Twitter, Globe, Instagram } from 'react-feather';
import { SEO } from '../components/SEO';
import { ResponsiveImage } from '../components/ResponsiveImage';
import {
  BlockContent,
  sanitySerializers
} from '../components/SanityBlockContent';

type PageQueryData = {
  sanityPost: SanityPost;
  site: {
    siteMetadata: SiteMetadata;
  };
};

export const pageQuery = graphql`
  query($postId: String) {
    sanityPost(id: { eq: $postId }) {
      id
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
      title
      description
      publishedAt(formatString: "YYYY-MM-DD")
      lastUpdated(formatString: "YYYY-MM-DD")
      author {
        _rawBio
        name
        twitter
        jobTitle
        website
        linkedin
        instagram
        slug {
          current
        }
        image {
          alt
          image {
            width
            height
            src
          }
        }
      }
      contributors {
        _rawBio
        name
        twitter
        jobTitle
        website
        linkedin
        instagram
        slug {
          current
        }
        image {
          alt
          image {
            width
            height
            src
          }
        }
      }
      mainImage {
        image {
          src
          width
          height
        }
        alt
      }
    }
    mdx(id: { eq: $postId }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        date
        author
        summary
        image
        socialSharingImage
        tweet
        pinterestImage
        seoTitle
        seoDescription
      }
    }
  }
`;

const Title = styled('h1')`
  color: ${(p) => p.theme.palette.grey[900]};
  font-size: 36px;

  @media (max-width: 800px) {
    font-size: 28px;
  }
`;

const PostMeta = styled('div')`
  color: ${(p) => p.theme.palette.grey.A700};
  margin-bottom: ${(p) => p.theme.spacing(4)}px;

  img {
    position: relative;
    border: 3px solid white;
    filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.5));
    top: 10px;
    display: inline-block;
    width: 36px;
    border-radius: 100%;
    margin-right: 8px;
  }
`;

const COPY_FONT_SIZE = '18px';
const COPY_LINE_HEIGHT = '36px';

const Article = styled('article')`
  p {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    a {
      border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      transition: 0.1s border-bottom linear;
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};

      &:hover {
        border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
      }
    }
    code {
      display: inline-block;
    }
  }

  blockquote {
    padding-left: ${(p) => p.theme.spacing(3)}px;
    border-left: 3px solid #ddd;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
    margin: 0;
    font-style: italic;
    color: #666;

    p {
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};
      margin-bottom: 0;
    }
  }

  code {
    display: block;
    background-color: #eee;
    padding: 0 6px;
    border-radius: 4px;
  }

  & div > p:first-of-type,
  & > p:first-of-type {
    font-size: 24px;
    line-height: 36px;

    a {
      font-size: inherit;
    }
  }

  img {
    border: 1px solid #eee;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    max-width: 100%;
    height: auto;
    margin: ${(p) => p.theme.spacing(3)}px auto ${(p) => p.theme.spacing(3)}px;
  }
`;

const FeaturedImage = styled('div')`
  max-width: 950px;
  margin: 90px auto 0;

  @media (max-width: 800px) {
    margin: 56px auto 0;
  }
`;

const ArticleWrapper = styled('div')`
  margin: 0 auto ${(p) => p.theme.spacing(4)}px;
  display: grid;
  grid-template-columns: 650px 1fr;
  grid-column-gap: 60px;
  max-width: 950px;
  padding: 0 12px;

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }

  ul,
  ol {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    code {
      display: inline-block;
    }

    a {
      border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
      color: ${(p) => p.theme.palette.primary.main};
      transition: 0.1s border-bottom linear;
      font-size: ${COPY_FONT_SIZE};
      line-height: ${COPY_LINE_HEIGHT};

      &:hover {
        border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;

const A = styled('a')`
  border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
  transition: 0.1s border-bottom linear;
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  &:hover {
    border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
  }

  p,
  ol,
  li & {
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};
  }
`;

const Ol = styled('ol')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  code {
    display: inline-block;
  }
`;

const Ul = styled('ul')`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};

  code {
    display: inline-block;
  }

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const Code = styled('code')`
  display: block;
  font-family: Consolas, Menlo, Courier, monospace;
  background-color: #efefef;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 0.9em;
  line-height: 1.5em;
  overflow-x: auto;
  max-height: 200px;
`;

const P = styled(Typography)`
  font-size: ${COPY_FONT_SIZE};
  line-height: ${COPY_LINE_HEIGHT};
  margin-bottom: ${(p) => p.theme.spacing(2)}px;

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.primary.main};
    color: ${(p) => p.theme.palette.primary.main};
    transition: 0.1s border-bottom linear;
    font-size: ${COPY_FONT_SIZE};
    line-height: ${COPY_LINE_HEIGHT};

    &:hover {
      border-bottom: 3px solid ${(p) => p.theme.palette.primary.main};
    }
  }

  code {
    font-family: Consolas, Menlo, Courier, monospace;
    background-color: #efefef;
    border-radius: 8px;
    padding: 0 12px;
    font-size: 0.9em;
    word-break: break-all;
  }
`;

const AuthorWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(28)}px;
  color: ${(p) => p.theme.palette.grey.A400};

  p a {
    border-bottom: 1px solid;
  }

  @media (max-width: 800px) {
    margin-top: ${(p) => p.theme.spacing(2)}px;
    display: block;
    justify-content: center;
    text-align: center;
  }
`;

const AuthorImage = styled('img')`
  width: 85px;
  border-radius: 100%;
  margin-right: ${(p) => p.theme.spacing(4)}px;
  border: 3px solid white;
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.1));

  @media (max-width: 800px) {
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

const AuthorName = styled(Typography)`
  font-weight: 700;
  margin-top: ${(p) => p.theme.spacing(1)}px;

  a {
    border: 0 !important;

    &:hover {
      color: ${(p) => p.theme.palette.primary.main};
    }
  }
`;

const SocialWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${(p) => p.theme.spacing(1)}px;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const SocialIcon = styled('a')`
  display: inline-block;
  font-size: 12px;
  transition: all ease-out 0.15s;
  margin-right: ${(p) => p.theme.spacing(1)}px;
  color: white;
  padding: ${(p) => p.theme.spacing(1)}px;
  width: 36px;
  height: 36px;
  border-radius: ${(p) => p.theme.custom.borderRadius.unit}px;

  &:hover {
    transform: scale(1.25);
  }
`;

const SanityAuthor = ({
  name,
  slug,
  _rawBio,
  instagram,
  twitter,
  website,
  linkedin,
  image,
  role
}: ISanityAuthor & { role: 'author' | 'contributor' }) => {
  const matches = new RegExp(/v[0-9]+\/(.+)/).exec(image.image.src);
  if (!matches) {
    return null;
  }
  const src = matches[1];
  const ASSET_URL = 'https://assets.affilimate.com';
  const toSize = (size: number, src: string) => {
    return `${ASSET_URL}/image/upload/w_${size}/${src}`;
  };

  const fullSrc = `${ASSET_URL}/${src}`;
  const webPSrc = src.replace('.png', '.webp').replace('.jpg', '.webp');
  const jpgSrc = src.replace('.png', '.jpg');

  return (
    <div style={{ marginBottom: '24px' }}>
      <picture>
        <source
          type="image/webp"
          srcSet={`${toSize(170, webPSrc)} 170w, ${toSize(85, webPSrc)} 85w`}
          sizes="100wv"
        />
        <source
          type="image/jpeg"
          srcSet={`${toSize(170, jpgSrc)} 170w, ${toSize(85, jpgSrc)} 85w`}
          sizes="100wv"
        />
        <AuthorImage
          src={fullSrc}
          alt={name}
          loading="lazy"
          width="85"
          height="85"
        />
      </picture>
      <div>
        <AuthorName
          variant="body1"
          style={{ display: 'inline-block', marginRight: '6px' }}
        >
          <Link to={`/blog/authors/${slug.current}/`}>{name}</Link>
        </AuthorName>
        <Typography variant="caption" component="p" color="textSecondary">
          {role.toUpperCase()}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          color="textSecondary"
          style={{ fontSize: '16px' }}
        >
          <BlockContent blocks={_rawBio} serializers={sanitySerializers} />
        </Typography>
        <div>
          {website && (
            <IconButton href={website} target="_blank">
              <Globe size={14} />
            </IconButton>
          )}
          {twitter && (
            <IconButton
              href={`https://twitter.com/${twitter}`}
              target="_blank"
              rel="nofollow"
            >
              <Twitter size={14} />
            </IconButton>
          )}
          {instagram && (
            <IconButton
              href={`https://instagram.com/${instagram}`}
              target="_blank"
              rel="nofollow"
            >
              <Instagram size={14} />
            </IconButton>
          )}
          {linkedin && (
            <IconButton href={linkedin} target="_blank" rel="nofollow">
              <Linkedin size={14} />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

type IconProps = {
  color?: string;
  size?: string | number;
};

const SocialShareButton = ({
  color,
  tooltip,
  icon: IconComponent,
  ...props
}: {
  color: string;
  icon: React.ComponentType<IconProps>;
  tooltip: string;
} & React.HTMLProps<HTMLAnchorElement>) => (
  <Tooltip title={tooltip} placement="bottom">
    <SocialIcon href="#" style={{ backgroundColor: color }} {...props}>
      <IconComponent size={20} />
    </SocialIcon>
  </Tooltip>
);

const SanitySocialSharing = ({ post }: { post: SanityPost }) => {
  const postUrl = encodeURIComponent(
    `https://affilimate.com/blog/${post.slug.current}/`
  );
  const tweet = encodeURIComponent(`${post.socialMessage} | via @affilimateio`);
  const twitterHref = `https://twitter.com/share?text=${tweet}&url=${postUrl}`;
  const facebookHref = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;
  const linkedInHref = `https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`;

  return (
    <SocialWrapper>
      <SocialShareButton
        color="#3c5a98"
        tooltip="Share on Facebook"
        href={facebookHref}
        icon={Facebook}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(facebookHref, 'facebook-share', 'width=550,height=235');
        }}
      />
      <SocialShareButton
        color="#3ea9dd"
        tooltip="Tweet this"
        icon={Twitter}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(twitterHref, 'twitter-share', 'width=550,height=235');
        }}
        href={twitterHref}
      />
      <SocialShareButton
        color="#0077B5"
        tooltip="Share on LinkedIn"
        icon={Linkedin}
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          window.open(linkedInHref, 'linkedin-share', 'width=550,height=235');
        }}
        href={linkedInHref}
      />
    </SocialWrapper>
  );
};

const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export default function Template({ data }: { data: PageQueryData }) {
  const { sanityPost } = data;

  const matches = sanityPost.mainImage?.image
    ? new RegExp(/v[0-9]+\/(.+)/).exec(sanityPost.mainImage.image.src)
    : false;
  const sharingPath = matches ? matches[1] : '';
  const sharingImage = `/images/${sharingPath}`;

  return (
    <Content>
      <>
        <SEO
          title={sanityPost.title}
          description={sanityPost.description}
          image={sharingImage}
          pathname={`/customers/${sanityPost.slug.current}/`}
          isArticle={true}
          publishedDate={sanityPost.publishedAt}
          lastUpdated={sanityPost.lastUpdated || sanityPost.publishedAt}
          author={sanityPost.author}
        />
        <FeaturedImage>
          {sanityPost.mainImage && sanityPost.mainImage.image && (
            <ResponsiveImage
              src={sanityPost.mainImage.image.src}
              alt={sanityPost.mainImage.alt}
              style={{ maxWidth: '100%', height: 'auto' }}
              width="1000"
              height="500"
              loading="eager"
            />
          )}
        </FeaturedImage>
        <ArticleWrapper>
          <Article>
            <Title>{sanityPost.title}</Title>
            <PostMeta>
              By {sanityPost.author.name} · Updated{' '}
              {formatDate(sanityPost.lastUpdated || sanityPost.publishedAt)}
            </PostMeta>
            <BlockContent
              blocks={sanityPost._rawBody}
              serializers={sanitySerializers}
            />
          </Article>
          <div>
            <AuthorWrapper>
              <SanityAuthor role="author" {...sanityPost.author} />
              {sanityPost.contributors && sanityPost.contributors.length > 0 && (
                <div>
                  {sanityPost.contributors.map((c) => (
                    <SanityAuthor
                      role="contributor"
                      {...c}
                      key={c.slug.current}
                    />
                  ))}
                </div>
              )}
            </AuthorWrapper>
          </div>
        </ArticleWrapper>
      </>
    </Content>
  );
}
